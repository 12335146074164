<template>
  <div class="event">
    <v-alert
      v-for="error in errorMessages"
      :key="error"
      type="error"
    >
      {{ error }}
    </v-alert>
    <Loading
      v-if="loading"
      :loading="loading"
      message="Loading Event"
    />
    <div v-if="event">
      <v-row v-if="isInvited">
        <v-col>
          <v-alert
            text
            type="info"
          >
            <v-row no-gutters>
              <v-col>
                You have been invited to this event.
              </v-col>
              <v-col
                :align="$vuetify.breakpoint.xsOnly ? 'left' : 'right'"
                cols="12"
                sm="6"
              >
                <v-btn
                  color="info"
                  class="mr-4"
                  :disabled="submitting"
                  @click="reply('confirm')"
                >
                  Accept
                </v-btn>
                <v-btn
                  color="error"
                  :disabled="submitting"
                  @click="reply('reject')"
                >
                  Reject
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
      </v-row>
      <v-row v-if="canLeave">
        <v-col>
          <v-alert
            text
            type="info"
          >
            <v-row no-gutters>
              <v-col>
                You are attending this event. Would you like to cancel your attendance?
              </v-col>
              <v-col
                :align="$vuetify.breakpoint.xsOnly ? 'left' : 'right'"
                cols="12"
                sm="6"
              >
                <v-btn
                  color="error"
                  :disabled="submitting"
                  @click="reply('reject')"
                >
                  Cancel
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
      </v-row>
      <v-row v-if="event.cancelled">
        <v-col>
          <v-alert type="error">
            This event has been cancelled.
          </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <div v-if="event.image">
              <v-img
                :src="event.image"
                height="200px"
                class="px-3"
              />
            </div>
            <v-card-title>
              <router-link
                v-if="event.quest"
                :to="{ name: 'Quest', params: { slug: event.quest.slug }}"
                class="mr-4"
              >
                <v-icon small>
                  $quest
                </v-icon>
              </router-link>
              {{ event.summary }}
            </v-card-title>
            <v-card-subtitle>
              <span v-if="event.is_all_day && isSingleDay">{{ startDate }}</span>
              <span v-else-if="event.is_all_day">{{ event.start_at|formatDate }} &#8211; {{ event.stop_at|formatDate }}</span>
              <span v-else>{{ event.start_at|formatDatetime }} &#8211; {{ event.stop_at|formatDatetime }}</span>
            </v-card-subtitle>
            <v-card-text>
              {{ event.description }}
            </v-card-text>
            <v-card-actions>
              <Share
                :title="event.summary"
                class="mr-2"
              />
              <Flag
                v-if="!canEdit"
                :object-id="event.id"
                app="quest"
                model="event"
                class="mr-2"
              />
              <v-btn
                v-if="canJoin"
                :disabled="submitting"
                color="primary"
                @click="reply('join')"
              >
                Join Event
              </v-btn>
              <v-btn
                v-if="canEdit"
                @click.stop="editDialog = true"
              >
                <v-icon>
                  $edit
                </v-icon>
              </v-btn>
              <v-btn
                v-if="canCancel"
                color="error"
                @click.stop="cancelDialog = true"
              >
                <v-icon>
                  $cancel
                </v-icon>
              </v-btn>
              <v-btn
                v-if="event.quest"
                :to="{ name: 'Quest', params: { slug: event.quest.slug }}"
              >
                Load Quest
              </v-btn>
              <v-dialog
                v-model="editDialog"
                :fullscreen="$vuetify.breakpoint.smAndDown"
                :max-width="$vuetify.breakpoint.thresholds.sm"
              >
                <v-card>
                  <v-toolbar>
                    <v-btn
                      icon
                      dark
                      @click="editDialog = false"
                    >
                      <v-icon>
                        $close
                      </v-icon>
                    </v-btn>
                    <v-toolbar-title>
                      Edit Event: {{ event.summary }}
                    </v-toolbar-title>
                  </v-toolbar>
                  <EventForm
                    :event="event"
                    :visible="editDialog"
                    :allow-attendees="true"
                    @eventSubmitted="updateEvent($event)"
                  />
                </v-card>
              </v-dialog>
              <v-dialog
                v-model="cancelDialog"
                :fullscreen="$vuetify.breakpoint.smAndDown"
                :max-width="$vuetify.breakpoint.thresholds.sm"
              >
                <v-card>
                  <v-toolbar>
                    <v-btn
                      icon
                      dark
                      @click="cancelDialog = false"
                    >
                      <v-icon>
                        $close
                      </v-icon>
                    </v-btn>
                    <v-toolbar-title>
                      Cancel Event: {{ event.summary }}
                    </v-toolbar-title>
                  </v-toolbar>
                  <EventCancel
                    :event="event"
                    @eventSubmitted="updateEvent($event)"
                  />
                </v-card>
              </v-dialog>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col
          v-if="canEdit || event.location"
          cols="12"
        >
          <v-card>
            <v-card-title>
              <v-row no-gutters>
                <v-col>
                  Location
                </v-col>
                <v-col
                  v-if="region"
                  cols="4"
                  align="right"
                >
                  <router-link :to="{ name: 'Region', params: { slug: region.slug }}">
                    <v-avatar align="right">
                      <v-img :src="region.image_thumbnail_url" />
                    </v-avatar>
                  </router-link>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-subtitle v-if="event.location">
              {{ event.location }}
            </v-card-subtitle>
            <v-card-text v-if="event.location">
              <EventMapView :event="event" />
            </v-card-text>
            <v-card-actions>
              <v-btn
                v-if="canEdit"
                @click.stop="mapDialog = true"
              >
                <span v-if="event.location">Edit Location</span>
                <span v-else>Add Location</span>
              </v-btn>
              <v-dialog
                v-model="mapDialog"
                :fullscreen="$vuetify.breakpoint.smAndDown"
                :max-width="$vuetify.breakpoint.thresholds.sm"
              >
                <v-card>
                  <v-toolbar>
                    <v-btn
                      icon
                      dark
                      @click="mapDialog = false"
                    >
                      <v-icon>
                        $close
                      </v-icon>
                    </v-btn>
                    <v-toolbar-title>
                      <span v-if="event.location">Edit</span><span v-else>Add</span> Location: {{ event.summary }}
                    </v-toolbar-title>
                  </v-toolbar>
                  <EventMapForm
                    :event="event"
                    :visible="mapDialog"
                    @eventMoved="updateEvent($event)"
                  />
                </v-card>
              </v-dialog>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12">
          <EventAttendeeList :event="event" />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import EventAttendeeList from '@/components/EventAttendeeList.vue'
import EventCancel from '@/components/EventCancel.vue'
import EventForm from '@/components/EventForm.vue'
import EventMapForm from '@/components/EventMapForm.vue'
import EventMapView from '@/components/EventMapView.vue'
import Flag from '@/components/Flag.vue'
import Loading from '@/components/Loading.vue'
import Share from '@/components/Share.vue'
import moment from 'moment'
import { allianceAPI } from '@/api'
import { mapState } from 'vuex'
import { parseResponseErrors } from '@/utils/errors'

export default {
  components: {
    EventAttendeeList,
    EventCancel,
    EventForm,
    EventMapForm,
    EventMapView,
    Flag,
    Loading,
    Share,
  },
  data: function() {
    return {
      event: null,
      errorMessages: null,
      loading: null,
      submitting: null,
      editDialog: null,
      mapDialog: null,
      cancelDialog: null,
    }
  },
  computed: {
    ...mapState({
      profile: state => state.auth.profile,
    }),
    startDate() {
      return moment(this.event.start_at).format('YYYY-MM-DD')
    },
    stopDate() {
      return moment(this.event.stop_at).format('YYYY-MM-DD')
    },
    isSingleDay() {
      if (this.startDate == this.stopDate) {
        return true
      }
      return false
    },
    isOver() {
      const end = new Date(this.event.stop_at)
      const now = new Date()
      if (now >= end) {
        return true
      }
      return false
    },
    myStatus() {
        const attendee = this.event.attendees.find(obj => obj.profile.id == this.profile.id)
        if (attendee) {
          return attendee.status
        }
        return null
    },
    isInvited() {
      if (!this.isOver && this.myStatus && this.myStatus == "invited") {
        return true
      }
      return false
    },
    canJoin() {
      if (this.isOver || this.isInvited || (this.myStatus && this.myStatus == "confirmed")) {
        return false
      }
      return true
    },
    canLeave() {
      if (!this.isOver && !this.event.cancelled && !this.isOrganizer && (this.myStatus && this.myStatus == "confirmed")) {
        return true
      }
      return false
    },
    isOrganizer() {
      return this.event.organized_by.id == this.profile.id
    },
    canEdit() {
      return this.isOrganizer && !this.isOver
    },
    canCancel() {
      return this.canEdit && !this.event.cancelled
    },
    region() {
      if (this.event.region) {
        return this.$store.getters['regions/getByID'](this.event.region.id) || null
      }
      return null
    }
  },
  created: function () {
    this.loadEvent()
  },
  methods: {
    loadEvent() {
      this.loading = true
      return allianceAPI.get(`/quest/event/${this.$route.params.id}/`)
        .then((response) => {
          this.event = response.data
        })
        .catch((error) => {
          this.errorMessages = parseResponseErrors(error)
        })
        .finally(() => this.loading = false)
    },
    updateEvent(event) {
      this.editDialog = false
      this.mapDialog = false
      this.event = event
    },
    reply(type) {
      this.submitting = true
      this.errorMessages = null
      return allianceAPI.patch(`/quest/event/${this.event.id}/${type}/`)
        .then((response) => {
          this.success = true
          this.event = response.data
        })
        .catch((error) => {
          this.errorMessages = parseResponseErrors(error)
          this.success = false
        })
        .finally(() => this.submitting = false)
    },
  },
}
</script>
