<template>
  <div id="event-view-map-container">
    <VueMapboxMap
      v-if="map"
      :map="map"
      :interactive="true"
      :lng="event.longitude"
      :lat="event.latitude"
      zoom="11"
      pitch="0"
      bearing="0"
    />
  </div>
</template>

<script>
import VueMapboxMap from '@benchmark-urbanism/vue-mapbox-map'
import mapboxgl from "mapbox-gl";

export default {
  components: {
    VueMapboxMap
  },
  props: {
    event: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data: function() {
    return {
      map: null,
      marker: null,
    }
  },
  watch: {
    event: function () {
      this.moveMap()
    },
  },
  mounted: function () {
    mapboxgl.accessToken = this.$mapboxAccessToken
    this.map = new mapboxgl.Map({
      container: 'event-view-map-container',
      style: this.$mapboxStyle,
    })
    this.map.on('load', () => {
      this.setMap()
    })
  },
  methods: {
    moveMap() {
      if (this.map) {
        this.map.flyTo({
          center: [this.event.longitude, this.event.latitude],
        })
        this.marker.setLngLat([this.event.longitude, this.event.latitude]).addTo(this.map);
      }
    },
    sizeMap() {
      this.map.resize()
    },
    setMap() {
      this.sizeMap()
      this.marker = new mapboxgl.Marker()
      this.marker.setLngLat([this.event.longitude, this.event.latitude]).addTo(this.map);
    },
  },
}
</script>

<style>
@import url("https://api.mapbox.com/mapbox-gl-js/v2.3.1/mapbox-gl.css");
#event-view-map-container {
  height: 500px;
}
</style>
