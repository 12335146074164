<template>
  <div>
    <v-card
      v-if="success"
      flat
      align="center"
    >
      <v-card-text>
        <v-icon
          size="200"
          color="primary"
        >
          $check
        </v-icon>
        <p class="text-h4">
          Event Cancelled
        </p>
      </v-card-text>
    </v-card>
    <div v-else>
      <v-alert
        v-for="error in errorMessages"
        :key="error"
        type="error"
      >
        {{ error }}
      </v-alert>
      <v-form
        ref="form"
        @submit.prevent="submit"
      >
        <v-card flat>
          <v-card-text>
            Are you sure you want to cancel this event?
          </v-card-text>
          <v-card-actions>
            <v-btn
              :disabled="submitting"
              type="submit"
              color="error"
            >
              Yes, cancel it.
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </div>
  </div>
</template>

<script>
import { allianceAPI } from '@/api'
import { parseResponseErrors } from '@/utils/errors'

export default {
  props: {
    event: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data: function() {
    return {
      errorMessages: [],
      submitting: false,
      success: false,
    }
  },
  methods: {
    submit: function () {
      if (!this.$refs.form.validate()) {
        return
      }
      this.submitting = true
      this.errorMessages = []
      const data = new FormData()
      data.append('cancelled', true)
      return allianceAPI.patch(`/quest/event/${this.event.id}/`, data)
        .then((response) => {
          this.success = true
          this.$refs.form.reset()
          this.$emit('eventSubmitted', response.data)
        })
        .catch((error) => {
          this.errorMessages = parseResponseErrors(error)
          this.success = false
        })
        .finally(() => this.submitting = false)
    },
  },
}
</script>
