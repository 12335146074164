<template>
  <v-form
    ref="form"
    @submit.prevent="submit"
  >
    <v-card-text>
      <v-alert
        v-for="error in errorMessages"
        :key="error"
        type="error"
      >
        {{ error }}
      </v-alert>
      <v-text-field
        v-model="location"
        :rules="locationRules"
        :disabled="submitting"
        type="text"
        label="Location Name"
        hint="Use the search box on the map to locate an address or point of interest, or drop a marker on the map and enter a name for the location here."
        persistent-hint
      />
    </v-card-text>
    <v-card-actions>
      <v-btn
        :disabled="submitting"
        type="submit"
        color="primary"
      >
        Submit
      </v-btn>
      <v-btn
        @click="getUserLocation"
      >
        <v-icon>
          $location
        </v-icon>
      </v-btn>
    </v-card-actions>
    <div id="event-edit-map-container">
      <VueMapboxMap
        v-if="map"
        :map="map"
        :interactive="true"
        :lng="scene.lng"
        :lat="scene.lat"
        :zoom="scene.zoom"
        :pitch="scene.pitch"
        :bearing="scene.bearing"
      />
    </div>
  </v-form>
</template>

<script>
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import VueMapboxMap from '@benchmark-urbanism/vue-mapbox-map'
import mapboxgl from "mapbox-gl";
import { allianceAPI } from '@/api'
import { mapState } from 'vuex'
import { parseResponseErrors } from '@/utils/errors'

export default {
  components: {
    VueMapboxMap
  },
  props: {
    event: {
      type: Object,
      required: false,
      default: null,
    },
    visible: {
      type: Boolean,
      required: true,
    },
  },
  data: function() {
    return {
      location: '',
      locationRules: [
        v => !!v || 'Field is required',
        v => v && v.length <= 1024 || 'Please restrain yourself to 1024 characters',
      ],
      submitting: false,
      success: false,
      errorMessages: [],
      map: null,
      marker: null,
      selectedLat: null,
      selectedLng: null,
      geocoder: null,
      scene: {
        lng: -104.823574,
        lat: 39.707895,
        zoom: 3,
        pitch: 0,
        bearing: 0,
      },
    }
  },
  computed: {
    ...mapState({
      userCoordinates: state => state.userlocation.coordinates,
    }),
  },
  watch: {
    visible: function (value) {
      if (value && this.map) {
        this.sizeMap()
      }
    },
  },
  mounted: function () {
    mapboxgl.accessToken = this.$mapboxAccessToken
    this.map = new mapboxgl.Map({
      container: 'event-edit-map-container',
      style: this.$mapboxStyle,
    })
    this.map.on('load', () => {
      this.setMap()
    })
  },
  methods: {
    moveMarker(event) {
      var coordinates = null
      // If it is a click the coordinates are in one place...
      if ('lngLat' in event) {
        coordinates = event.lngLat;
      // If it is a geocoder result the coordinates are elsewhere...
      } else if ('result' in event) {
        this.location = event.result["place_name_en-US"]
        coordinates = {
          lng: event.result.geometry.coordinates[0],
          lat: event.result.geometry.coordinates[1],
        }
      }
      this.selectedLat = coordinates.lat
      this.selectedLng = coordinates.lng
      this.marker.setLngLat(coordinates).addTo(this.map);
    },
    sizeMap () {
      this.map.resize()
    },
    setMap () {
      this.sizeMap()
      this.marker = new mapboxgl.Marker()
      this.map.on('click', this.moveMarker)
      this.map.addControl(
        this.geocoder = new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl,
          marker: false,
          trackProximity: true,
        })
      )
      this.geocoder.setProximity({
        longitude: this.scene.lng,
        latitude: this.scene.lat,
      })
      this.geocoder.on('result', this.moveMarker)
      if (this.event.location) {
        this.location = this.event.location
        this.moveMarker({lngLat: {lng: this.event.longitude, lat: this.event.latitude}})
        this.map.flyTo({
          center: [this.event.longitude, this.event.latitude],
          zoom: 11,
        })
      } else {
        this.flyToUser()
      }
    },
    flyToUser() {
      if (this.userCoordinates) {
        this.scene.lat = this.userCoordinates.lat
        this.scene.lng = this.userCoordinates.lng
        this.scene.zoom = 11
        if (this.map) {
          this.map.flyTo({
            center: [this.scene.lng, this.scene.lat],
            zoom: this.scene.zoom,
          })
        }
      }
    },
    async getUserLocation () {
      await this.$store.dispatch('userlocation/get')
      if (this.userCoordinates) {
        this.flyToUser()
      }
    },
    submit: function () {
      this.errorMessages = []
      if (!this.$refs.form.validate()) {
        return
      }
      if (!this.selectedLat || !this.selectedLng) {
        this.errorMessages.push("A marker must be placed on the map to set the event's coordinates.")
        return
      }
      this.submitting = true
      this.success = false
      const data = new FormData()
      data.append('location', this.location)
      data.append('latitude', this.selectedLat)
      data.append('longitude', this.selectedLng)
      return allianceAPI.patch(`/quest/event/${this.event.id}/`, data)
        .then((response) => {
          this.success = true
          this.$emit('eventMoved', response.data)
        })
        .catch((error) => {
          this.errorMessages = parseResponseErrors(error)
          this.success = null
        })
        .finally(() => this.submitting = false)
    },
  },
}
</script>

<style>
@import url("https://api.mapbox.com/mapbox-gl-js/v2.3.1/mapbox-gl.css");
#event-edit-map-container {
  height: 500px;
}
</style>
